import React, { useState } from "react";

function usePagination() {
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(1);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  return {
    rowsPerPage,
    page,
    handleChangeRowsPerPage,
    handleChangePage,
    setPage,
    setRowsPerPage,
  };

}

export default usePagination;
