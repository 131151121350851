import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { setTokenAuth } from "../../../redux/tokenSlice";
import { setUserAuth } from "../../../redux/userSlice";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
  // extendedInstanceSubSchema,
  instanceValidationSchema,
} from "../../../utils/validations/validations";
import { useGetCustomersQuery } from "../../../redux/api/customerApi";
import HelperText from "../../../components/HelperText/HelperText";
import Label from "../../../components/Label/Label";
import BasicBreadCrumbs from "../../../components/BasicBreadcrumbs/BasicBreadcrumbs";
import Sectionheader from "../../../components/SectionHeader/SectionHeader";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import type { } from "@mui/x-date-pickers/themeAugmentation";
import {
  useCreateInstanceMutation,
  useEditInstanceMutation,
  useGetInstanceByIdQuery,
  useUpdateRadeaInstanceMutation,
} from "../../../redux/api/instanceApi";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";
import Resellers from "./Resellers";
import SalesContacts from "./SalesContacts";
import EncodingMethods from "./EncodingMethods";
import { EncodingMethodsTypes } from "../../../utils/enum/enum";

const languages = [
  { key: "English", value: "en" },
  { key: "Finnish", value: "fi" },
  { key: "German", value: "de" },
  { key: "Spanish", value: "es" },
  { key: "French", value: "fr" },
  { key: "Italian", value: "it" },
  { key: "Dutch", value: "nl" },
];
const featureListInitial = [
  { key: "Association", value: "association" },
  { key: "Goods In", value: "goodsin" },
  { key: "Goods Out", value: "goodsout" },
  { key: "Inventory", value: "inventory" },
  { key: "Locate", value: "locate" },
];
const initialValues = {
  name: "",
  customer: "",
  domain: "radea.io",
  subdomain: "",
  encoding: "NID64",
  parameters: {
    django: {
      cuname: "",
      cuemail: "",
      cufirstname: "",
      culastname: "",
      culanguage: "en",
    },
  },
  subscription_valid_until: new Date(),
  subscription_metadata: {
    encoding:
      [
        {
          method: "NID64",
          meta: {}
        },
        {
          method: "NID32",
          meta: {}
        },
        {
          method: "SGTIN96",
          meta: { code: "" }
        },
        {
          method: "LOOKUP",
          meta: {}
        }
      ]
    ,
    contactPerson: "",
    hh_features: [] as { key: string; value: string }[],
    sales_contacts: [
      { sales_email: "", sales_firstname: "", sales_lastname: "" },
    ],
    resellers: [{ rs_email: "", rs_firstname: "", rs_lastname: "" }],
    rs_company_name: "",
    instance_description: "",
    company_name: "",
  },
  subscription_reader_limit: 1,
  subscription_leaf_location_limit: 10,
};

export default function AddEditInstance() {
  const { state } = useLocation();
  const { id, isInitialized } = state as { id: string; isInitialized: boolean };
  const { accessToken } = useAppSelector(setTokenAuth);
  const { user } = useAppSelector(setUserAuth);
  const { data: Instance } = useGetInstanceByIdQuery(id);
  const [createInstance] = useCreateInstanceMutation();
  const [encodingMethod, setEncodingMethod] = useState("NID64");
  const [readerLimit, setReaderLimit] = useState(0);
  const [locationLimit, setLocationLimit] = useState(0);
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [multiEncoding, setMultiEncoding] = useState<{ method: string, meta: unknown }[]>([{
    method: EncodingMethodsTypes.NID64,
    meta: {}
  }]);
  // const [isActivate, setIsactivate] = useState(false);
  // const [arrayFeature, setArrayFeature] = useState<
  //   { key: string; value: string }[]
  // >([]);
  // const [featureList, setFeatureList] =
  //   useState<{ key: string; value: string }[]>(featureListInitial);
  const [updateReadeInstance] = useUpdateRadeaInstanceMutation();
  const [editInstance] = useEditInstanceMutation();
  const { data: Customer } = useGetCustomersQuery({
    debounceValue: "",
    rowsPerPage: null,
  });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { createErrorMessage } = useCreateErrorMessage();

  if (id && Instance) {
    initialValues.name = Instance?.name;
    initialValues.customer = Instance?.customer;
    initialValues.domain = Instance?.domain;
    initialValues.subdomain = Instance?.subdomain;
    initialValues.parameters.django.cuemail =
      Instance?.parameters?.django?.cuemail;
    initialValues.parameters.django.cuname =
      Instance?.parameters?.django?.cuname;
    initialValues.parameters.django.cufirstname =
      Instance?.parameters?.django?.cufirstname;
    initialValues.parameters.django.culastname =
      Instance?.parameters?.django?.culastname;
    initialValues.parameters.django.culanguage = Instance?.parameters?.django
      ?.culanguage
      ? Instance?.parameters?.django?.culanguage
      : "en";
    initialValues.subscription_valid_until = Instance?.subscription_valid_until;
    initialValues.subscription_metadata.contactPerson =
      Instance?.subscription_metadata?.contactPerson;
    initialValues.subscription_metadata.hh_features = Instance
      ?.subscription_metadata?.hh_features
      ? [...Instance.subscription_metadata.hh_features]
      : [];
    initialValues.subscription_reader_limit =
      Instance?.subscription_reader_limit;
    initialValues.subscription_leaf_location_limit =
      Instance?.subscription_leaf_location_limit;
    initialValues.subscription_metadata.instance_description =
      Instance?.subscription_metadata?.instance_description;
    initialValues.subscription_metadata.company_name =
      Instance?.subscription_metadata?.company_name;
    initialValues.encoding = Instance?.encoding ? Instance?.encoding : EncodingMethodsTypes.NID64;
    initialValues.subscription_metadata.encoding = Instance
      ?.subscription_metadata?.encoding
      ? Instance?.subscription_metadata?.encoding
      : [{
        method: EncodingMethodsTypes.NID64,
        meta: {}
      }];
    // initialValues.subscription_metadata.encoding.meta.sgtin96 = Instance?.subscription_metadata?.encoding?.meta.sgtin96
    initialValues.subscription_metadata.sales_contacts =
      Instance?.subscription_metadata?.sales_contacts;
    initialValues.subscription_metadata.resellers =
      Instance?.subscription_metadata?.resellers;
    initialValues.subscription_metadata.rs_company_name =
      Instance?.subscription_metadata?.rs_company_name;
  } else {
    initialValues.name = "";
    initialValues.customer = "";
    initialValues.domain = "radea.io";
    initialValues.subdomain = "";
    initialValues.parameters.django.cuemail = "";
    initialValues.parameters.django.cuname = "";
    initialValues.parameters.django.cufirstname = "";
    initialValues.parameters.django.culastname = "";
    initialValues.parameters.django.culanguage = "en";
    initialValues.subscription_valid_until = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    initialValues.subscription_metadata.contactPerson = user?.email
      ? user?.email
      : "";
    initialValues.subscription_metadata.hh_features = [...featureListInitial];
    initialValues.subscription_reader_limit = 10;
    initialValues.subscription_leaf_location_limit = 10;
    initialValues.subscription_metadata.instance_description = "";
    initialValues.subscription_metadata.company_name = "";
    initialValues.encoding = EncodingMethodsTypes.NID64;
    initialValues.subscription_metadata.encoding = [
      {
        method: EncodingMethodsTypes.NID64,
        meta: {}
      }
    ];
    initialValues.subscription_metadata.sales_contacts = [
      { sales_email: "", sales_firstname: "", sales_lastname: "" },
    ];
    initialValues.subscription_metadata.resellers = [
      { rs_email: "", rs_firstname: "", rs_lastname: "" },
    ];
    initialValues.subscription_metadata.rs_company_name = "";
  }

  useEffect(() => {
    if (Instance) {
      setReaderLimit(Instance.subscription_reader_limit);
      setExpirationDate(Instance.subscription_valid_until);
      setLocationLimit(Instance.subscription_leaf_location_limit);
      setEncodingMethod(Instance.encoding);
      setMultiEncoding(Instance?.subscription_metadata?.encoding);
      // setArrayFeature(
      //   Instance.subscription_metadata?.hh_features
      //     ? [...Instance.subscription_metadata.hh_features]
      //     : []
      // );
      // setFeatureList(
      //   Instance.subscription_metadata?.hh_features
      //     ? [...Instance.subscription_metadata.hh_features]
      //     : []
      // );
    }
  }, [Instance]);
  const subDomain = /^[a-z0-9-]*$/;
  const extendedInstanceSubSchema = instanceValidationSchema.shape({
    subdomain: Yup.string()
      .matches(
        subDomain,
        "Invalid Subdomain, Use only lowercase letters (a-z), '_', and digits (0-9)"
      )
      .max(15, "Ensure this field has no more than 15 characters.")
      .test(
        "subdomain",
        "This subdomain already exist!",
        async (value: any) => {
          const response = await fetch(
            window.location.host.substring(
              0,
              window.location.host.indexOf(":")
            ) === "localhost"
              ? "http://localhost:8001/customer-instance/"
              : `${`${window.location.protocol}//${window.location.host
                .substring(0, window.location.host.indexOf("."))
                .concat("api")
                .concat(
                  window.location.host
                    .split(":")[0]
                    .substring(window.location.host.indexOf("."))
                    .concat("/customer-instance/")
                )}`}`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
          );
          const instanceData = await response.json();
          const result = instanceData?.results?.map(
            (instance: any) => instance.subdomain
          );
          return !result?.includes(value);
        }
      )
      .required("Subdomain is Required!"),
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: id ? instanceValidationSchema : extendedInstanceSubSchema,
    validateOnChange: true,
    onSubmit: (values) => {

      //send only in unique encoding methods
      const methodsArray = values.encoding.split(",");
      const uniqueMethodsArray = methodsArray.filter((method, index, self) => self.indexOf(method) === index);
      const uniqueMethodsString = uniqueMethodsArray.join(",");
      const finalValues = { ...values, encoding: uniqueMethodsString };
      // eslint-disable-next-line no-unused-expressions
      id
        ? editInstance({ id, patch: { ...finalValues, subscription_metadata: { ...finalValues.subscription_metadata, extension: Instance?.subscription_metadata?.extension } } })
          .unwrap()
          .then((payload) => {
            if (payload) {
              if (
                readerLimit !== values.subscription_reader_limit ||
                expirationDate !== values.subscription_valid_until ||
                locationLimit !== values.subscription_leaf_location_limit ||
                encodingMethod !== finalValues.encoding ||
                multiEncoding !== finalValues.subscription_metadata.encoding
                // ||
                // !featureList.every((element) =>
                //   values.subscription_metadata.hh_features.some(
                //     ({ id }) => id === element.value
                //   )
                // )
              ) {
                updateReadeInstance(id)
                  .unwrap()
                  .then((payload) => {
                    if (payload) {
                      enqueueSnackbar("success", {
                        variant: "success",
                        autoHideDuration: 2000,
                      });
                    }
                  })
                  .catch((error) => {
                    createErrorMessage({
                      error,
                      message: "Failed to update instances.",
                    });
                  });
              } else {
                enqueueSnackbar("success", {
                  variant: "success",
                  autoHideDuration: 2000,
                });
              }
            }
            navigate("/");
          })
          .catch((error) => {
            createErrorMessage({
              error,
              message: "Failed to update an instance.",
            });
          })
        : createInstance(finalValues)
          .unwrap()
          .then((payload) => {
            if (payload) {
              enqueueSnackbar("success", {
                variant: "success",
                autoHideDuration: 2000,
              });
            }
            navigate("/");
          })
          .catch((error) => {
            createErrorMessage({
              error,
              message: "Failed to create a new instance.",
            });
          });
    },
  });
  // const handleCheckboxActivate = () => {
  //   setIsactivate((isActivate) => !isActivate);
  // };

  // const handleCheckBoxArray = (e: any, key: string, value: string) => {
  //   if (e.target.checked) {
  //     setArrayFeature([...arrayFeature, { key, value }]);
  //     setFeatureList([...featureList, { key, value }]);
  //   } else {
  //     setArrayFeature(arrayFeature.filter((id) => id.value !== value));
  //     setFeatureList(featureList.filter((id) => id.value !== value));
  //   }
  // };

  // useEffect(() => {
  //   formik.setFieldValue("subscription_metadata.hh_features", arrayFeature);
  // }, [arrayFeature.length]);

  // useEffect(() => {
  //   if (
  //     Instance &&
  //     id &&
  //     Instance?.subscription_metadata?.hh_features?.length > 0
  //   ) {
  //     setIsactivate(true);
  //   }
  // }, [Instance, id]);

  //breadcrumb
  const breadcrumbs = [
    {
      title: "Instances",
      link: "/",
    },
    {
      title: id ? "Edit Customer Instance" : "Add Customer Instance",
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BasicBreadCrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label
              text="Instance Name"
              isRequired
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            <TextField
              fullWidth
              id="name"
              name="name"
              error={formik.touched.name && Boolean(formik.errors.name)}
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={!!(!!id && isInitialized === true)}
            />
            {formik.errors.name && formik.touched.name && (
              <HelperText text={formik.errors.name} />
            )}
          </Grid>
          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label
              text="Customer company name"
              isRequired
              error={
                formik?.touched?.subscription_metadata?.company_name &&
                Boolean(formik?.errors?.subscription_metadata?.company_name)
              }
            />
            <TextField
              fullWidth
              id="company_name"
              name="subscription_metadata.company_name"
              value={formik.values.subscription_metadata.company_name}
              onChange={formik.handleChange}
            />
            {formik?.errors?.subscription_metadata?.company_name &&
              formik.values.subscription_metadata?.company_name?.length ===
              0 && (
                <HelperText
                  text={formik?.errors?.subscription_metadata?.company_name}
                />
              )}
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              pb: 2,
              display: {
                xs: "none",
                lg: "none",
                sm: "none",
                xl: "none",
                md: "none",
              },
            }}
          >
            <Label text="Domain" />
            <TextField
              fullWidth
              id="host"
              name="domain"
              value={formik.values.domain}
              onChange={formik.handleChange}
              error={formik?.touched?.domain && Boolean(formik?.errors?.domain)}
              disabled
            />
          </Grid>
          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label
              text="Subdomain"
              isRequired
              error={
                formik?.touched?.subdomain && Boolean(formik?.errors?.subdomain)
              }
            />
            <Box
              sx={{ display: "flex" }}>
              <TextField
                fullWidth
                id="subdomain"
                name="subdomain"
                value={formik.values.subdomain}
                onChange={formik.handleChange}
                error={
                  formik?.touched?.subdomain && Boolean(formik?.errors?.subdomain)
                }
                disabled={!!(!!id && isInitialized === true)}
              />
              <Typography
                sx={{
                  alignSelf:
                    formik.errors.subdomain && formik.values.subdomain.length === 0
                      ? "center"
                      : "end",
                  pl: "0.75rem !important",
                }}
                variant="body1">.radea.io
              </Typography>
            </Box>
            {formik.errors.subdomain && formik.touched.subdomain && (
              <HelperText text={formik.errors.subdomain} />
            )}
          </Grid>
          {/* below is for multiple encoding */}
          <EncodingMethods formik={formik} />
          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label text="Default language" />
            <Select
              fullWidth
              defaultValue="en"
              id="culanguage"
              name="parameters.django.culangauge"
              disabled={!!(!!id && isInitialized === true)}
              value={formik.values.parameters.django.culanguage}
              onChange={(event) =>
                formik.setFieldValue(
                  "parameters.django.culanguage",
                  event.target.value
                )
              }
              error={
                formik?.touched?.parameters?.django?.culanguage &&
                Boolean(formik?.errors?.parameters?.django?.culanguage)
              }
            >
              {languages.map((lang) => (
                <MenuItem key={lang.key} value={lang.value}>
                  {lang.key}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label text="Expiration" />
            <DesktopDatePicker
              disablePast
              inputFormat="dd/MM/yyyy"
              value={formik.values.subscription_valid_until}
              onChange={(value) =>
                formik.setFieldValue(
                  "subscription_valid_until",
                  value?.toISOString()
                )
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>

          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label text="Reader limit" />
            <TextField
              fullWidth
              type="number"
              id="reader_limit"
              name="subscription_reader_limit"
              value={formik.values.subscription_reader_limit}
              onChange={formik.handleChange}
              error={
                formik?.touched?.subscription_reader_limit &&
                Boolean(formik?.errors?.subscription_reader_limit)
              }
            />
            {formik.errors.subscription_reader_limit &&
              formik.touched.subscription_reader_limit && (
                <HelperText text={formik.errors.subscription_reader_limit} />
              )}
          </Grid>
          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label text="Configurable location limit" />
            <TextField
              fullWidth
              type="number"
              id="leaf_location_limit"
              name="subscription_leaf_location_limit"
              value={formik.values.subscription_leaf_location_limit}
              onChange={formik.handleChange}
              error={
                formik?.touched?.subscription_leaf_location_limit &&
                Boolean(formik?.errors?.subscription_leaf_location_limit) &&
                formik.values.subscription_leaf_location_limit < 0
              }
            />
            {formik.errors.subscription_leaf_location_limit &&
              formik.touched.subscription_leaf_location_limit && (
                <HelperText
                  text={formik.errors.subscription_leaf_location_limit}
                />
              )}
          </Grid>
          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label
              text="Instance technical contact"
              isRequired
              error={
                formik?.touched?.subscription_metadata?.contactPerson &&
                Boolean(formik?.errors?.subscription_metadata?.contactPerson) &&
                formik.values.subscription_metadata?.contactPerson?.length === 0
              }
            />
            <TextField
              fullWidth
              id="contact"
              name="subscription_metadata.contactPerson"
              value={formik.values.subscription_metadata.contactPerson}
              onChange={formik.handleChange}
              type="email"
            />
            {formik?.errors?.subscription_metadata?.contactPerson &&
              formik.values.subscription_metadata?.contactPerson?.length ===
              0 && (
                <HelperText
                  text={formik?.errors?.subscription_metadata?.contactPerson}
                />
              )}
          </Grid>

          <Grid item xs={6} sx={{ pb: 2 }}>
            <Label text="Description" />
            <TextField
              fullWidth
              name="subscription_metadata.instance_description"
              value={formik.values.subscription_metadata.instance_description}
              onChange={formik.handleChange}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <Sectionheader
              title="RADEA.IO Instance Administrator User"
              tooltip="Instance administrator users"
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ pb: 2 }}>
                <Label
                  text="Admin username"
                  isRequired
                  error={
                    formik?.touched?.parameters?.django?.cuname &&
                    Boolean(formik?.errors?.parameters?.django?.cuname) &&
                    formik.values.parameters?.django?.cuname.length === 0
                  }
                />
                <TextField
                  fullWidth
                  id="cuname"
                  name="parameters.django.cuname"
                  value={formik.values.parameters.django.cuname}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.parameters?.django?.cuname &&
                    Boolean(formik?.errors?.parameters?.django?.cuname)
                  }
                  disabled={!!(!!id && isInitialized === true)}
                />
                {formik?.errors?.parameters?.django?.cuname && (
                  <HelperText
                    text={formik?.errors?.parameters?.django?.cuname}
                  />
                )}
              </Grid>
              <Grid item xs={6} sx={{ pb: 2 }}>
                <Label
                  text="Admin e-Mail"
                  isRequired
                  error={
                    formik?.touched?.parameters?.django?.cuemail &&
                    Boolean(formik?.errors?.parameters?.django?.cuemail)
                  }
                />
                <TextField
                  fullWidth
                  id="cuemail"
                  name="parameters.django.cuemail"
                  value={formik.values.parameters.django.cuemail}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.parameters?.django?.cuemail &&
                    Boolean(formik?.errors?.parameters?.django?.cuemail)
                  }
                  disabled={!!(!!id && isInitialized === true)}
                />
                {formik?.errors?.parameters?.django?.cuemail && (
                  <HelperText
                    text={formik?.errors?.parameters?.django?.cuemail}
                  />
                )}
              </Grid>
              <Grid item xs={6} sx={{ pb: 2 }}>
                <Label
                  text="Admin first name"
                  isRequired
                  error={
                    formik?.touched?.parameters?.django?.cufirstname &&
                    Boolean(formik?.errors?.parameters?.django?.cufirstname) &&
                    formik.values.parameters?.django?.cufirstname?.length === 0
                  }
                />
                <TextField
                  fullWidth
                  id="cufirstname"
                  name="parameters.django.cufirstname"
                  value={formik.values.parameters.django.cufirstname}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.parameters?.django?.cufirstname &&
                    Boolean(formik?.errors?.parameters?.django?.cufirstname) &&
                    formik.values.parameters?.django?.cufirstname?.length === 0
                  }
                  disabled={!!(!!id && isInitialized === true)}
                />
                {formik?.errors?.parameters?.django?.cufirstname &&
                  formik.values.parameters?.django?.cufirstname?.length ===
                  0 && (
                    <HelperText
                      text={formik?.errors?.parameters?.django?.cufirstname}
                    />
                  )}
              </Grid>

              <Grid item xs={6} sx={{ pb: 2 }}>
                <Label
                  text="Admin last name"
                  isRequired
                  error={
                    formik?.touched?.parameters?.django?.culastname &&
                    Boolean(formik?.errors?.parameters?.django?.culastname) &&
                    formik.values.parameters?.django?.culastname?.length === 0
                  }
                />
                <TextField
                  fullWidth
                  id="culastname"
                  name="parameters.django.culastname"
                  value={formik.values.parameters.django.culastname}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.parameters?.django?.culastname &&
                    Boolean(formik?.errors?.parameters?.django?.culastname) &&
                    formik.values.parameters?.django?.culastname?.length === 0
                  }
                  disabled={!!(!!id && isInitialized === true)}
                />
                {formik?.errors?.parameters?.django?.culastname &&
                  formik.values.parameters?.django?.culastname?.length ===
                  0 && (
                    <HelperText
                      text={formik?.errors?.parameters?.django?.culastname}
                    />
                  )}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sx={{ pb: 2 }}>
          <FormControlLabel
            checked={isActivate}
            control={
              <Checkbox color="default" onChange={handleCheckboxActivate} />
            }
            label="Activate handled configuration"
          />
        </Grid>
        {isActivate && (
          <>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Typography variant="body1">
                Activate handheld features
              </Typography>
            </Grid>
            {featureListInitial?.map((item) => (
              <Grid
                item
                xs={item.value === "goodsout" ? 3 : 2}
                key={item.value}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      onChange={(e) =>
                        handleCheckBoxArray(e, item.key, item.value)
                      }
                      checked={formik.values.subscription_metadata.hh_features.some(
                        (feature) => feature.value === item.value
                      )}
                    />
                  }
                  label={item.key}
                />
              </Grid>
            ))}
            <Grid item xs={12} sx={{ pb: 2 }}>
              {formik.values.subscription_metadata?.hh_features?.length ===
                0 &&
                isActivate && (
                  <HelperText text="You need to choose at least one feature" />
                )}
            </Grid>
          </>
        )} */}

          <Grid item xs={12}>
            <Sectionheader title="Sales contact" />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Label
              text="Sales org"
              isRequired
              error={
                formik.touched.customer &&
                Boolean(formik.errors.customer) &&
                formik.values.customer.length === 0
              }
            />
            <FormControl fullWidth>
              <Select
                labelId="customer-id"
                id="customer-id"
                name="customer"
                disabled={!!(!!id && isInitialized === true)}
                value={formik.values.customer}
                onChange={(event) =>
                  formik.setFieldValue("customer", event.target.value)
                }
                error={
                  formik.touched.customer &&
                  Boolean(formik.errors.customer) &&
                  formik.values.customer.length === 0
                }
              >
                {Customer &&
                  Customer.results.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
              </Select>
              {formik.errors.customer &&
                formik.values.customer.length === 0 && (
                  <HelperText text={formik.errors.customer} />
                )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ pb: 2 }}>
            <SalesContacts formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <Sectionheader title="Resellers" />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Label text="Reseller company name" />
            <TextField
              fullWidth
              id="reseller_company_name"
              name="subscription_metadata.rs_company_name"
              value={formik.values.subscription_metadata.rs_company_name}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Resellers formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <ActionDialog
              onClose={() => navigate("/")}
              confirmText="Save"
              closeText="Cancel"
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
