import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { UserRolesEnum } from "../../utils/enum/enum";
import PageNotFound from "../../views/PageNotFound";
import { useAppSelector } from "../../hooks/storeHooks";
import { setUserAuth } from "../../redux/userSlice";



type UserRole = `${UserRolesEnum}`;

type AuthorizedRoutesProps = {
  authPool: UserRole[]; // list of authorized user roles
};

const isAuthorized = (roles: UserRole[], authPool: UserRole[]) =>
  roles?.some((item) => authPool?.includes(item));

export function AuthorizedRoutes({ authPool }: AuthorizedRoutesProps) {
  const { user, isLoggedIn } = useAppSelector(setUserAuth);
  if (!isLoggedIn) return <Navigate to="/login" />;
  if (isAuthorized(user?.roles, authPool)) return <Outlet />;
  return <PageNotFound />;
}


export function IsUserAuthenticated() {
  const { isLoggedIn } = useAppSelector(setUserAuth);
  if (isLoggedIn) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
}

export function IsUserUnAuthenticated() {
  const { isLoggedIn } = useAppSelector(setUserAuth);
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}
