import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { UserRolesEnum } from "../../../utils/enum/enum";
import Label from "../../../components/Label/Label";
import HelperText from "../../../components/HelperText/HelperText";
import BasicTooltip from "../../../components/BasicTooltip";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUserByIdQuery,
} from "../../../redux/api/userApi";
import { userValidationSchema } from "../../../utils/validations/validations";

export type UserRole = `${UserRolesEnum}`;

type AddEditCustomerProps = {
  id?: string;
  isOpen: boolean;
  handleCloseDialog: () => void;
  isOwnAccount?: boolean;
};
const initialValues = {
  username: "",
  email: "",
  first_name: "",
  last_name: "",
  roles: [] as UserRole[],
};

export default function AddEditUser({
  id,
  isOpen,
  handleCloseDialog,
  isOwnAccount = false,
}: AddEditCustomerProps) {
  const [createUser] = useCreateUserMutation();
  const { data: user } = useGetUserByIdQuery(id || skipToken);
  const [updateUser] = useUpdateUserMutation();

  const { createErrorMessage } = useCreateErrorMessage();

  if (id && user) {
    initialValues.username = user?.username;
    initialValues.first_name = user?.first_name || "";
    initialValues.last_name = user?.last_name || "";
    initialValues.email = user?.email;
    initialValues.roles = user?.roles || [];
  } else {
    initialValues.username = "";
    initialValues.first_name = "";
    initialValues.last_name = "";
    initialValues.email = "";
    initialValues.roles = [];
  }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-unused-expressions
      id
        ? updateUser({ id, patch: { ...values } })
          .unwrap()
          .then((payload) => {
            if (payload) {
              enqueueSnackbar("success", {
                variant: "success",
                autoHideDuration: 2000,
              });
              handleCloseDialog();
            }

            navigate("/user-tabs");
          })
          .catch((error) => {
            createErrorMessage({
              error,
              message: "Failed to update a user.",
            });
            handleCloseDialog();
          })
        : createUser({ ...values })
          .unwrap()
          .then((payload) => {
            if (payload) {
              enqueueSnackbar("success", {
                variant: "success",
                autoHideDuration: 2000,
              });
              handleCloseDialog();
            }
            navigate("/user-tabs");
          })
          .catch((error) => {
            createErrorMessage({
              error,
              message: "Failed to update a user.",
            });
            handleCloseDialog();
          });
    },
  });

  const handleRoleChange = (role: UserRole) => {
    formik.setFieldValue("roles", [role]);
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={id ? "Edit User" : "Add User"}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container gap={1} spacing={2} justifyContent="space-between">

          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              label="First name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              label="Last name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item sm={12}>
            <Label
              text="Roles"
              error={Boolean(formik.errors.roles)}
              isRequired
            />
            <FormControl>
              <RadioGroup
                row
                value={formik.values.roles}
                aria-labelledby="radio-buttons-group-label"
                name="roles"
                onChange={(e) => handleRoleChange(e.target.value as UserRole)}
              >
                <Box sx={{ width: "100%", display: "inline-flex" }}>
                  <Box sx={{ mr: 4, display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value={UserRolesEnum.VIEWER}
                      control={<Radio disabled={isOwnAccount && Boolean(id)} />}
                      label={`${UserRolesEnum.VIEWER}`
                      }
                    />
                    <BasicTooltip
                      text="Viewer has access to Item Tracking, Inventory, Reports and Location view."
                    />
                  </Box>
                  <Box sx={{ mr: 4, display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value={UserRolesEnum.OPERATOR}
                      control={<Radio disabled={isOwnAccount && Boolean(id)} />}
                      label={`${UserRolesEnum.OPERATOR}`
                      }
                    />
                    <BasicTooltip
                      text="Operator has access to Locations, Add Items, Reports, Item Tracking and Inventory views."
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value={UserRolesEnum.ADMINISTRATOR}
                      control={<Radio disabled={isOwnAccount && Boolean(id)} />}
                      label={`${UserRolesEnum.ADMINISTRATOR}`}
                    />
                    <BasicTooltip
                      text="Administrator has full access to all views including user management and system settings."
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
            {
              formik?.errors?.roles && (
                <HelperText text={`${formik?.errors?.roles}`} />
              )}
          </Grid>
        </Grid>
        <ActionDialog onClose={handleCloseDialog} />
      </form>
    </CommonDialog>
  );
}
