// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/destructuring-assignment */
type ErrorResponseType = {
  detail?: {
    non_field_errors?: string[];
  } | string;
};
function ErrorResponse(error: ErrorResponseType) {
  if (typeof error.detail === "object" && error?.detail?.non_field_errors && error?.detail?.non_field_errors?.length > 0) {
    return error?.detail?.non_field_errors?.[0];
  } if (typeof (error?.detail) === "string") {
    return error?.detail;
  }
  if (typeof error.detail === "object" && (error?.detail as { reader_configuration_ids: string[] })?.reader_configuration_ids && (error?.detail as { reader_configuration_ids: string[] })?.reader_configuration_ids.length > 0) {
    return (error?.detail as { reader_configuration_ids: string[] })?.reader_configuration_ids[0]
  }
  return null; // Add a return statement at the end of the function
}
export default ErrorResponse;