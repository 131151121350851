import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../../components/TabPanel/TabPanel";
import UserManagement from "./UserManagement";
import DeactivatedUserManagement from "./DeactivatedUserManagement";

export default function UserTabs() {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/user-tabs") setValue(0);
    else if (path === "/user-tabs/users" && value !== 0) setValue(0);
    else if (path === "/user-tabs/deactivated-users" && value !== 1) setValue(1);
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={false} onChange={handleChange} aria-label="reader tabs">
          <Tab
            label="Active Users"
            component={NavLink}
            to="users"
            className={value === 0 ? "activeTab" : "inactiveTab"}
          />
          <Tab
            label="Deactivated Users"
            component={NavLink}
            to="deactivated-users"
            className={value === 1 ? "activeTab" : "inactiveTab"}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <UserManagement tabValue={value} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DeactivatedUserManagement />
      </TabPanel>
    </Box>
  );
}
