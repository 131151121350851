import appApi from "./appApi";
import { UserLogin, UserAuthToken, Users, User } from "./api.types";

const userApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    userLogin: build.mutation<
      UserLogin,
      { username: string; password: string }
    >({
      query: (loginInfo) => ({
        url: `/auth/token/two-factor/`,
        method: "POST",
        body: loginInfo,
      }),
    }),
    userAuthToken: build.mutation<
      UserAuthToken,
      { verificator: string; secret_phrase: string }
    >({
      query: (secrets) => ({
        url: `/auth/token/`,
        method: "POST",
        body: secrets,
      }),
    }),
    getUsers: build.query<
      Users,
      { debounceValue: string; rowsPerPage: number }
    >({
      query: ({ debounceValue, rowsPerPage }) =>
        `/accounts/users/?search=${debounceValue}&page_size=${rowsPerPage}`,
      providesTags: ["Users"],
    }),
    getUserById: build.query<User, string>({
      query: (userId) => `/accounts/users/${userId}/`,
      providesTags: (result, error, userId) => [{ type: "Users", id: userId }],
    }),
    createUser: build.mutation<User, Omit<User, "id">>({
      query(userFormData) {
        return {
          url: "/accounts/users/",
          method: "POST",
          body: userFormData,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateUser: build.mutation<
      User,
      {
        id: string;
        patch: Omit<User, "id">;
      }
    >({
      query(user) {
        return {
          url: `/accounts/users/${user.id}/`,
          method: "PUT",
          body: user.patch,
        };
      },
      invalidatesTags: (result, error, user) => [
        { type: "Users", id: user.id },
        "Users",
      ],
    }),
    deleteUser: build.mutation<null, string>({
      query: (userId) => ({
        url: `/accounts/users/${userId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    forgetPassword: build.mutation<void, { username: string; email: string }>({
      query(userFormData) {
        return {
          url: "/accounts/forgot-password/",
          method: "POST",
          body: userFormData,
        };
      },
    }),
    getPasswordResetInfoById: build.query<{ detail: string }, string>({
      query: (resetId) => `/accounts/forgot-password/${resetId}/`,
      providesTags: (result, error, resetId) => [
        { type: "Users", id: resetId },
      ],
    }),
    changePassword: build.mutation<
      void,
      {
        id: string;
        old_password: string;
        new_password: string;
      }
    >({
      query(userFormData) {
        return {
          url: `/accounts/users/${userFormData.id}/set_password/`,
          method: "POST",
          body: userFormData,
        };
      },
    }),
    adminResetUserPassword: build.mutation<void, string>({
      query(userId) {
        return {
          url: `/accounts/users/${userId}/reset_password/`,
          method: "POST",
        };
      },
    }),
    getUserPaginated: build.query<Users, { page: number; rowsPerPage: number, debounceValue: string, active: boolean }>(
      {
        query: ({ page, rowsPerPage, debounceValue, active }) =>
          `/accounts/users/?page=${page}&page_size=${rowsPerPage}&search=${debounceValue}&is_active=${active}`,
        providesTags: ["Users"],
      }
    ),
    getUserGroups: build.query<void, void>({
      query: () => `/accounts/groups/`,
    }),
    activateUser: build.mutation<void, string>({
      query: (userId) => ({
        url: `/accounts/users/${userId}/activate_user/`,
        method: "POST",
      }),
    }),
  })
})

// eslint-disable-next-line import/prefer-default-export
export const {
  useUserLoginMutation,
  useUserAuthTokenMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUserPaginatedQuery,
  useGetUserByIdQuery,
  useDeleteUserMutation,
  useGetUserGroupsQuery,
  useChangePasswordMutation,
  useForgetPasswordMutation,
  useGetPasswordResetInfoByIdQuery,
  useActivateUserMutation,
} = userApi;
