import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../hooks/storeHooks";
import { isOnProduction } from "../../utils/functions/functions";
import { useGetBackendVersionQuery } from "../../redux/api/systemApi";
import { setUserAuth } from "../../redux/userSlice";
import { PALATTE_PRIMARY_MAIN_COLOR } from "../../utils/constants/index";
import NavLinks from "./NavLinks";

const drawerWidth = 15;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBarWrapper = styled("div")(() => ({
  "& .MuiDrawer-paper": {
    width: `${drawerWidth}rem`,
    boxSizing: "border-box",
    backgroundColor: PALATTE_PRIMARY_MAIN_COLOR,
  },
  "& .MuiListItem-root": {
    color: "#fff",
    display: "block",
  },
  "& svg": {
    color: "#fff",
  },
  "& a": {
    textDecoration: "none",
    "& .MuiTypography-root": {
      color: "#cfd9e0",
    },
  },
  "& a:hover": {
    "& .MuiTypography-root": {
      color: "#fff",
    },
  },
  "& a.active": {
    backgroundColor: "#fff",
    display: "block",
    "& .MuiTypography-root": {
      color: "#000",
      textDecoration: "none",
    },
    "& svg": {
      color: "#000",
    },
  },
}));

function SideBar({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const theme = useTheme();
  const { data: version } = useGetBackendVersionQuery();
  const { user } = useAppSelector(setUserAuth);

  return (
    <SideBarWrapper>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "inherit",
          }}
        >
          <List>
            <NavLinks roles={user?.roles} />
          </List>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mb: "0.50rem",
            }}
          >
            <List>
              {!isOnProduction && (
                <>
                  <ListItem sx={{ textAlign: "center", pb: 0 }}>
                    <a
                      href="https://bradycorp.atlassian.net/wiki/spaces/NRTD/pages/3944513554/Brady+admin+tool+frontend+app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#FCFCFC",
                          fontFamily: "Roboto",
                          fontWeight: "300",
                          fontSize: "0.75rem",
                        }}
                      >
                        Frontend version: {process.env?.FRONTEND_SW_VERSION}
                      </Typography>
                    </a>
                  </ListItem>

                  <ListItem sx={{ textAlign: "center", pb: 0 }}>
                    <a
                      href="https://bradycorp.atlassian.net/wiki/spaces/NRTD/pages/3944480788/Brady+admin+tool+backend+app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#FCFCFC",
                          fontFamily: "Roboto",
                          fontWeight: "300",
                          fontSize: "0.75rem",
                        }}
                      >
                        Backend version: {version && version.version}
                      </Typography>
                    </a>
                  </ListItem>
                </>
              )}
              <ListItem sx={{ textAlign: "center", pb: 0 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#FCFCFC",
                    fontFamily: "Roboto",
                    fontWeight: "300",
                    fontSize: "0.75rem",
                  }}
                >
                  {!isOnProduction && (
                    <Box component="span">Release Target: </Box>
                  )}
                  <Box component="span">{process.env?.RELEASE_CANDIDATE}</Box>
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </SideBarWrapper>
  );
}

export default SideBar;
