import React from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button, DialogActions, MenuItem } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import CheckboxWithLabel from "../../../components/FormikComponents/CheckBoxLabel";
import {
  useCreateReaderTypeMutation,
  useGetReaderTypeByIdQuery,
  useUpdateReaderTypeMutation,
} from "../../../redux/api/readerTypeApis";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import customTheme from "../../../theme/customTheme";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import { TextField } from "../../../components/FormikComponents/TextField";
import { Select } from "../../../components/FormikComponents/Select";
import { readerTypeValidationSchema } from "../../../utils/validations/validations";

import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";

type initialValuesType = {
  name: string;
  ui_configuration: { [key: string]: string };
  disabled: boolean;
  family: string;
};

type AddEditReaderAppProps = {
  id?: string;
  isOpen: boolean;
  isEdit: boolean;
  preview: boolean;
  handleCloseDialog: () => void;
};
const initialValues = {
  name: "",
  ui_configuration: {
    antenna: "",
    bfa: "",
  },
  disabled: false,
  family: "",
};

export default function AddEditReaderType({
  id,
  isOpen,
  handleCloseDialog,
  isEdit,
  preview,
}: AddEditReaderAppProps) {
  const [createReaderType] = useCreateReaderTypeMutation();
  const [editReaderType] = useUpdateReaderTypeMutation();
  const { data: singleReaderType } = useGetReaderTypeByIdQuery(id || skipToken);

  const { createErrorMessage } = useCreateErrorMessage();

  if ((id && singleReaderType) || (isEdit && singleReaderType)) {
    initialValues.name = singleReaderType.name;
    initialValues.ui_configuration.antenna =
      singleReaderType.ui_configuration.antenna;
    initialValues.ui_configuration.bfa = singleReaderType.ui_configuration.bfa;
    initialValues.disabled = singleReaderType.disabled;
    initialValues.family = singleReaderType.family;
  } else {
    initialValues.name = "";
    initialValues.ui_configuration.antenna = "";
    initialValues.ui_configuration.bfa = "";
    initialValues.disabled = false;
    initialValues.family = "";
  }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleReaderTypeFormSubmit = (values: initialValuesType) => {
    // eslint-disable-next-line no-unused-expressions
    id
      ? editReaderType({ id, patch: values })
        .unwrap()
        .then((payload) => {
          if (payload) {
            enqueueSnackbar("success", {
              variant: "success",
              autoHideDuration: 2000,
            });
            handleCloseDialog();
          }
          navigate("/reader-type");
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: `Failed to update a new reader type.${error?.data?.detail?.name ? error?.data?.detail?.name : ""}`,
          });
          handleCloseDialog();
        })
      : createReaderType(values)
        .unwrap()
        .then((payload) => {
          if (payload) {
            enqueueSnackbar("success", {
              variant: "success",
              autoHideDuration: 2000,
            });
            handleCloseDialog();
          }
          navigate("/reader-type");
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: `Failed to create a new reader type.${error?.data?.detail?.name ? error?.data?.detail?.name : ""}`,
          });
          handleCloseDialog();
        });
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={
        id && !preview
          ? "Edit Reader Type"
          : preview
            ? "Reader Type"
            : "Add Reader Type"
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={readerTypeValidationSchema}
        onSubmit={(values) => handleReaderTypeFormSubmit(values)}
      >
        {() => (
          <Form>
            <Field
              component={TextField}
              id="name"
              name="name"
              label="Reader Type"
            />
            <Field
              component={Select}
              id="family"
              name="family"
              label="Reader Family"
            >
              <MenuItem value="fx">Fixed reader</MenuItem>
              <MenuItem value="hh">Handheld reader</MenuItem>
            </Field>

            <Field
              component={TextField}
              id="antenna"
              name="ui_configuration.antenna"
              label="Antenna"
              type="number"
            />
            {isEdit && (
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="disabled"
                Label={{ label: "Disabled" }}
              />
            )}
            {id && !isEdit ? (
              <DialogActions
                sx={{
                  paddingTop: "1rem",
                  borderTop: "0.0625rem solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#fff",
                    color: customTheme.palette.primary.main,
                  }}
                  onClick={handleCloseDialog}
                >
                  Close
                </Button>
              </DialogActions>
            ) : (
              <ActionDialog onClose={handleCloseDialog} />
            )}
          </Form>
        )}
      </Formik>
      {/* </form> */}
    </CommonDialog>
  );
}
