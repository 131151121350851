// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Button,
  Box,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { UIButtonVariants } from "../../utils/enum/enum";
import {
  useGetUserPaginatedQuery,
  useActivateUserMutation,
} from "../../redux/api/userApi";
import StyledTable from "../../theme/CustomTable/CustomTable";
import { useAppSelector } from "../../hooks/storeHooks";
import { setTableSeting } from "../../redux/settingSlice";
import useDebounce from "../../hooks/useDebounce";
import usePagination from "../../hooks/usePagination";
import Search from "../../components/Search/Search";

enum SortingDirectionEnum {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

type SortingDirection = `${SortingDirectionEnum}`;

type HeadCell = {
  id: string;
  label: string;
  isSorting: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: "username",
    label: "Username",
    isSorting: true,
  },

  {
    id: "email",
    label: "Email",
    isSorting: false,
  },
  {
    id: "firstName",
    label: "First name",
    isSorting: false,
  },

  {
    id: "lastname",
    label: "Last name",
    isSorting: false,
  },
  {
    id: "actions",
    label: "Actions",
    isSorting: false,
  },
];

export default function DeactivatedUserManagement() {
  const { dense } = useAppSelector(setTableSeting);
  const [name, setName] = React.useState("");
  const debounceValue = useDebounce(name, 700);
  const [activateUser] = useActivateUserMutation()
  const {
    rowsPerPage,
    page,
    setPage,
    handleChangeRowsPerPage,
    handleChangePage,
  } = usePagination();
  const [order, setOrder] = useState<SortingDirection>(
    SortingDirectionEnum.DESCENDING
  );
  const { data: Users, refetch } =
    useGetUserPaginatedQuery({ page, rowsPerPage, debounceValue, active: false }, { refetchOnMountOrArgChange: true })
  const [orderBy, setOrderBy] = useState("name");

  const { enqueueSnackbar } = useSnackbar();

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: SortingDirection, orderBy: string) {
    return order === SortingDirectionEnum.ASCENDING
      ? (a: Record<string, unknown>, b: Record<string, unknown>) =>
        descendingComparator(a, b, orderBy)
      : (a: Record<string, unknown>, b: Record<string, unknown>) =>
        -descendingComparator(a, b, orderBy);
  }


  const handleActivateUser = (rowId: string) => {
    activateUser(rowId)
      .unwrap()
      .then(() => {
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 2000,
        })
        refetch()
      }
      )
      .catch(() =>
        enqueueSnackbar("Failed", {
          variant: "error",
          autoHideDuration: 2000,
        })
      )
  };


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setPage(1);
  };


  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Search name={name} handleSearch={handleSearch} />
      </Box>
      <StyledTable>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            sx={dense ? { marginTop: "1rem" } : null}
          >
            <TableHead>
              <TableRow>
                {headCells.map((item) => (
                  <TableCell key={item.id}>
                    {item.isSorting ? (
                      <TableSortLabel
                        active={orderBy === `${item.id}`}
                        direction={orderBy === `${item.id}` ? order : "asc"}
                        onClick={createSortHandler(item.id)}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Users?.results &&
                Users?.results
                  ?.slice(rowsPerPage * 0, rowsPerPage * (page + 1))
                  ?.sort(getComparator(order, orderBy))
                  ?.map((data) => (
                    <TableRow
                      key={uuidv4()}
                    >
                      <TableCell>{data?.username}</TableCell>
                      <TableCell>{data?.email}</TableCell>
                      <TableCell>{data?.first_name}</TableCell>
                      <TableCell>{data?.last_name}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            handleActivateUser(data.id);
                          }}
                          variant={UIButtonVariants.OUTLINED}
                        >
                          Activate User
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTable>
      <TablePagination
        rowsPerPageOptions={[15, 25, 50, 100]}
        component="div"
        count={Users?.count || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
