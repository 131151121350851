import React from "react";
import { UserRolesEnum } from "../../../utils/enum/enum";
import AdminNavLinks from "./AdminNavLinks";
import OperatorNavLinks from "./OperatorNavLinks";
import ViewerNavLinks from "./ViewerNavLinks";

type UserRole = `${UserRolesEnum}`;

type NavLinksProps = {
  roles?: UserRole[];
};

export default function NavLinks({ roles = [] }: NavLinksProps) {
  if (roles?.includes(UserRolesEnum.ADMINISTRATOR)) {
    return <AdminNavLinks />;
  }
  if (roles?.includes(UserRolesEnum.OPERATOR)) {
    return <OperatorNavLinks />;
  }
  if (roles?.includes(UserRolesEnum.VIEWER)) {
    return <ViewerNavLinks />;
  }
  return <ViewerNavLinks />;
}
