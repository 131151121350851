export enum UIComponentVarinats {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export enum UIComponentColorVariants {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum UIButtonVariants {
  CONTAINED = "contained",
  OUTLINED = "outlined",
  TEXT = "text",
  UNDERLINED = "underlined",
}

export enum ComponentSize {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}
export enum HelperTextVariant {
  STANDARD = "standard",
  FILLED = "filled",
  OUTLINED = "outlined",
}

export enum PlacedPosition {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
}

export enum ApplicationTypes {
  FRONTEND = "radeaio-core-ui",
  BACKEND = "radeaio-core-backend",
}

export enum ExtensionNamesTypes {
  RADEA_IO_SENSOR_DATA = "radeaio-sensor-data"
}

export enum ExtensionValuesTypes {
  SENSOR = "sensor",
}

export enum UIComponentSizeVarinats {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum EncodingMethodsTypes {
  NID64 = "NID64",
  NID32 = "NID32",
  SGTIN96 = "SGTIN96",
  LOOKUP = "LOOKUP",
}

export enum UserRolesEnum {
  VIEWER = "viewer",
  OPERATOR = "operator",
  ADMINISTRATOR = "administrator",
}
