// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import { addDays, differenceInDays } from "date-fns";
import { ExtensionNamesTypes, ExtensionValuesTypes } from "../enum/enum";
import { Extension } from "../../views/InstanceManagement/AddExtension/integrationFormTypes";

export function calcLicenceExpiration(subExpiry: Date | undefined) {
  const daysUntillExp = addDays(new Date(), 30);

  if (subExpiry) {
    const daysUntillExpToTime = new Date(daysUntillExp).getTime();
    const expirationDateToTime = new Date(subExpiry).getTime();

    if (expirationDateToTime <= daysUntillExpToTime) {
      return differenceInDays(new Date(subExpiry), new Date());
    }
  }
  return null;
}

export const isOnProduction = () => process.env?.ON_PRODUCTION === "yes";

export function validateVersion(
  deployedTagName: string | undefined,
  formikAppTagName: string
) {
  const validateCode = deployedTagName?.localeCompare(formikAppTagName, "en", {
    sensitivity: "base",
  });

  if (validateCode === 1) {
    return "Warning! Dowgrading to lower version is dangerous.";
  }
  return "";
}


//extracting names so that we can add to subscription_metadata
export function extractExtensionNames(extensions: Extension[], tagLength: number) {
  if (tagLength === 0) {
    return []
  }
  const extensionNames: string[] = []
  const extensionNamesData = extensions
    .filter((item) => item.extension_name !== "")
    .map((extension) => extension.extension_name);
  if (extensionNamesData.includes(ExtensionNamesTypes.RADEA_IO_SENSOR_DATA)) {
    extensionNames.push(ExtensionValuesTypes.SENSOR)
  }
  //more if statements can be added here in the futire for other types of extensions
  return extensionNames
}
