import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import NavLinkItem from "./NavLinkItem";
import { sideViewerNavLinks } from "./constantMenu";

function ViewerNavLinks() {
  const [advancedToolsOpen, setAdvancedToolsOpen] = useState(false);
  const locationPath = useLocation();

  return (
    <>
      {sideViewerNavLinks.map((menu) => (
        <React.Fragment key={menu.menuKey}>
          <NavLinkItem
            keyItem={menu.menuKey}
            url={menu.urlLink}
            text={menu.textLink}
            icon={menu.icon}
            onClick={() =>
              menu.isSub && setAdvancedToolsOpen(!advancedToolsOpen)
            }
            iconRight={
              advancedToolsOpen ? (
                <ExpandLessOutlinedIcon />
              ) : (
                <ExpandMoreOutlinedIcon />
              )
            }
            isSubMenu={menu.isSub}
          />
          {menu.isSub && (
            <Collapse
              in={
                advancedToolsOpen ||
                locationPath.pathname === "/reader-type" ||
                locationPath.pathname === "/reader-app"
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <NavLinkItem
                  keyItem="readerType"
                  url="/reader-type"
                  text="Reader Type"
                  marginLeft="1rem"
                  icon={<SettingsInputAntennaIcon />}
                />
              </List>
              <List component="div" disablePadding>
                <NavLinkItem
                  keyItem="readerApp"
                  url="/reader-app"
                  text="Reader App"
                  marginLeft="1rem"
                  icon={<AppRegistrationIcon />}
                />
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default ViewerNavLinks;
