import React, { Suspense } from "react";
import Loading from "../../components/Loading";

const PageNotFound = React.lazy(
  () => import(/* webpackChunkName: "pageNotFound" */ "./PageNotFound")
);

function LazyPageNotFound() {
  return (
    <Suspense fallback={<Loading />}>
      <PageNotFound />
    </Suspense>
  );
}

export default LazyPageNotFound;
